.signin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.signin .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
  padding: 4em;

  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.signin form {
  width: 100%;
}

.signin-error {
  width: 100%;
  text-align: center;
}
