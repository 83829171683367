.user-list-title {
	display: flex;
}

.user-list-title h1 {
	margin-right: 1em;
	margin-bottom: 1em;
}

table .user-id {
	width: 6em !important;
	text-align: center !important;
}

table .user-name {
	width: 10em !important;
	text-align: center !important;
}

table .user-button {
	width: 1em !important;
}
