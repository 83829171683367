.lecture-view-body {
  display: flex;
  flex-direction: column;
}

.lecture-view-body label {
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  font-size: 16px;
}

.lecture-view-body label input {
  margin-left: 1em;
  flex: 1 1 auto;
}

.lecture-view-permission svg {
	margin-left: 1em;
	margin-right: 0.25em;
    cursor: pointer;
}

.lecture-view-description {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
  font-size: 16px;
}

.lecture-view-description textarea {
  margin-top: 0.5em;
}

.lecture-view-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.lecture-view-buttons .button + .button {
  margin-left: 3em;
}
