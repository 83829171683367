.banner-view-body {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.banner-view-image {
  display: flex;
  align-items: center;
  gap: 1em;
  flex: 1;
}

.banner-view-image h2 {
  width: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 20px;
}

.banner-view-image label {
  font-weight: bold;
  font-size: 14px;
}

.banner-view-image-wrapper {
    flex: 1;
}

.banner-view-image img {
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    padding: 1em;
    min-height: 48px;
    width: 100%;
    object-fit: contain;
}
