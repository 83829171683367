.access-list-title {
	display: flex;
}

.access-list-title h1 {
	margin-right: 1em;
}

table .access-id {
	width: 6em !important;
	text-align: center !important;
}

table .access-user-email {
	width: 15em !important;
	text-align: center !important;
}

table .access-user-name {
  width: 15em !important;
	text-align: center !important;
}

table .access-time {
	text-align: center !important;
}
