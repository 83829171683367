.custom-paginator {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1.5em;
}

.custom-paginator-first, .custom-paginator-last,
.custom-paginator-prev, .custom-paginator-next {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5rem;
	height: 1.5rem;
	margin: 0 0.125rem;
	border: 1px solid #D6D6D6;
	font-size: 16px;
	background-color: #E8E8E8;
}

.custom-paginator-item {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5rem;
	height: 1.5rem;
	margin: 0 0.125rem;
	border: 1px solid #D6D6D6;
	font-size: 12px;
}
