.lecture-list-title {
	display: flex;
}

.lecture-list-title h1 {
	margin-right: 1em;
}

table .lecture-id {
	width: 6em !important;
	text-align: center !important;
}

table .lecture-title {
	font-weight: bold !important;
}

table .lecture-button {
	width: 1em !important;
}

table .lecture-time {
	width: 8em !important;
	text-align: center !important;
}
