.notice-add-body {
  display: flex;
  flex-direction: column;
}

.notice-add-body label {
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  font-size: 16px;
}

.notice-add-body label input {
  margin-left: 1em;
  flex: 1 1 auto;
}

.notice-add-permission svg {
	margin-left: 1em;
	margin-right: 0.25em;
    cursor: pointer;
}

.notice-add-description {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
  font-size: 16px;
}

.notice-add-description textarea {
  margin-top: 0.5em;
}

.notice-add-error {
  width: 100%;
  text-align: center;
}

.notice-add-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.notice-add-buttons .button + .button {
  margin-left: 3em;
}
